<template>
  <div class="alarm-table">
    <el-table 
      :data="records"
      border
      :cell-class-name="tableCellClassName"
      header-cell-class-name="table-header"
      >
      <el-table-column align="center" label="时间" prop="date"></el-table-column>
      <el-table-column align="center" label="机器名" prop="machineName"></el-table-column>
      <el-table-column align="center" label="ID" prop="machineId"></el-table-column>
      <el-table-column align="center" label="行为" prop="behavior"></el-table-column>
      <el-table-column align="center" label="处理状态" prop="statusDesc">
        <template v-slot="scope">
          <div class="status-column">
            <div class="dot"></div>
            <div>{{scope.row.statusDesc}}</div>
          </div>
        </template>
      </el-table-column>
        <el-table-column label="操作">
          <span :class="`iconfont icon-review`"></span>
        </el-table-column>
    </el-table>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="currentPage"
      :page-sizes="[10, 20, 50, 100]"
      :page-size="pageSize"
      layout="slot, sizes, prev, pager, next, jumper"
      :total="total"
      background
      class="sf-pagination"
    >
      <slot>
        <div class="total-page">{{`共${total}条记录 第${currentPage}/${totalPage}页`}}</div>
        <div style="flex:1"></div>
      </slot>
    </el-pagination>
  </div>
</template>
<script>
import { get } from 'lodash';
import * as api from '@constants/apis';
let loading = null;

export default {
  data(){
    return {
      records:[],
      total: 0,
      totalPage: 0,
      currentPage: 1,
      pageSize: 20, 
      loading: true,
    }
  },
  mounted() {
    this.getData();
  },
  methods:{
    async getData() {
      try {
        loading = this.$loading({
          lock: true,
          spinner: 'el-icon-loading',
          customClass: 'sf-loading',
          target: document.querySelector('.alarm-table'),
        });
        const res = await this.$httpRequest.get(api.ALARM_DETAIL, {
          params: {
            pageSize: this.pageSize,
            pageNumber: this.currentPage,
          }
        })
        this.records = get(res, ['data'], []).map((item) => ({
          date: item.createTime,
          machineName: item.machineName,
          machineId: item.ip,
          behavior: item.behaviour,
          status: item.dealStatus,
          statusName: item.dealStatus === '0' ? 'complete' : (item.dealStatus === '1' ? 'inProgress' : 'pending'),
          statusDesc: item.dealStatusName,
        }))
        this.total = get(res, ['dataCount'], 0);
        this.pageSize = get(res, ['pageSize'], 0);
        this.pageNumber = get(res, ['pageNo'], this.pageNumber);
        this.totalPage = get(res, ['pageCount'], 0);
      } catch (err) {
        console.log(err);
      } finally {
        loading.close();
      }
    },
    tableCellClassName({row}) {
      return `table-cell ${row.statusName}`;
    },
    handleCurrentChange(pageNumber) {
      this.pageNumber = pageNumber;
      this.getData();
    },
    handleSizeChange(pageSize) {
      this.pageSize = pageSize;
      this.getData();
    }
  }
}
</script>
<style lang="scss">
.alarm-table {
  padding: $padding;
  border-radius: 0 $border-radius $border-radius $border-radius;
  @include background-color-content();
  .table-cell {
    font-size: 13px;
    color: $color-text-regular;
    .iconfont {
      color: $color-text-regular !important;
    }
    &.pending {
      color: #171921;
      font-weight: 500;
      .status-column {
        .dot {
          background-color: $color-danger;
        }
      }
    }
    &.inProgress {
      .status-column {
        .dot {
          background-color: $color-warning;
        }
      }
    }
    &.complete {
      .status-column {
        .dot {
          background-color: $color-success;
        }
      }
    }
  }
  .status-column{
    @include flex-center();
    .dot {
      margin-right: $padding-s;
      width: 8px;
      height: 8px;
      border-radius: 100%;
    }
  }
}
</style>